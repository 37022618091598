import React from 'react';

export const AlternateBackground = ({ children }) => {
    return (
        <div className="relative">
            <div className="absolute inset-0 bg-gray-100 opacity-60 z-0">
                {/* Add some subtle shapes or patterns here if desired */}
                <div className="absolute top-0 left-0 w-full h-full bg-secondary opacity-5 transform -skew-y-6"></div>
            </div>
            <div className="relative z-10">
                {children}
            </div>
        </div>
    );
};