export const theme = {
    colors: {
        primary: '#000000',
        secondary: '#4CAF50', // A fresh green color
        background: '#FFFFFF',
        text: '#333333',
    },
    fonts: {
        sans: ['Inter', 'sans-serif'],
    },
};

export const applyTheme = () => {
    const root = document.documentElement;
    Object.entries(theme.colors).forEach(([key, value]) => {
        root.style.setProperty(`--color-${key}`, value);
    });
};