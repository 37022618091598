import React, {useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import {Mail, User, Briefcase, Phone, Send, X} from 'lucide-react';

export const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        companyName: '',
        phoneNumber: '',
        email: '',
        about: ''
    });
    const [errors, setErrors] = useState({});
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.name.trim()) formErrors.name = "Name is required";
        if (!formData.companyName.trim()) formErrors.companyName = "Company name is required";
        if (!formData.email) {
            formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email is invalid";
        }
        if (formData.phoneNumber && !/^\+?[1-9]\d{1,14}$/.test(formData.phoneNumber)) {
            formErrors.phoneNumber = "Phone number is invalid";
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const form = e.target;
            const data = new FormData(form);
            fetch("https://formspree.io/f/xjkblvbd", {
                method: form.method,
                body: data,
                headers: {
                    'Accept': 'application/json'
                }
            }).then(response => {
                if (response.ok) {
                    setShowSuccessPopup(true);
                    setFormData({
                        name: '',
                        companyName: '',
                        phoneNumber: '',
                        email: '',
                        about: ''
                    });
                } else {
                    response.json().then(data => {
                        if (Object.hasOwn(data, 'errors')) {
                            alert(data["errors"].map(error => error["message"]).join(", "));
                        } else {
                            alert("Oops! There was a problem submitting your form");
                        }
                    });
                }
            }).catch((error) => {
                console.error("Form submission error:", error);
                alert("Oops! There was a problem submitting your form");
            });
        }
    };

    const SuccessPopup = () => (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
            <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-2xl font-bold text-primary">Thank You!</h3>
                    <button onClick={() => setShowSuccessPopup(false)} className="text-gray-500 hover:text-gray-700">
                        <X size={24}/>
                    </button>
                </div>
                <p className="text-lg text-gray-600 mb-4">We've received your message and will get back to you soon.</p>
                <button
                    onClick={() => setShowSuccessPopup(false)}
                    className="w-full py-2 px-4 bg-secondary text-white rounded-md hover:bg-opacity-90 transition duration-300"
                >
                    Close
                </button>
            </div>
        </motion.div>
    );

    return (
        <section id="contact" className="relative py-20 bg-gray-50">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.h2
                    className="text-4xl sm:text-5xl font-bold mb-8 text-center text-primary"
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                >
                    Let's Connect!
                </motion.h2>
                <motion.div
                    className="bg-white p-8 rounded-lg shadow-lg"
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{delay: 0.2, duration: 0.5}}
                >
                    <div className="text-center mb-8">
                        <p className="text-xl text-gray-600 mb-4">
                            Ready to dive into the world of AI? We're just a message away!
                        </p>
                        <p className="text-lg text-gray-600 font-semibold">
                            Let's make some AI magic happen together!
                        </p>
                    </div>
                    <form onSubmit={handleSubmit} method="POST" className="space-y-6">
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                                    Name
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                    <div
                                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <User className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    </div>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        className={`block w-full pl-10 pr-3 py-2 sm:text-sm border rounded-lg focus:ring-secondary focus:border-secondary ${errors.name ? 'border-red-500' : 'border-gray-400'}`}
                                        placeholder="Your name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.name && <p className="mt-2 text-sm text-red-600">{errors.name}</p>}
                            </div>
                            <div>
                                <label htmlFor="companyName" className="block text-sm font-medium text-gray-700 mb-1">
                                    Company Name
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                    <div
                                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <Briefcase className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    </div>
                                    <input
                                        type="text"
                                        name="companyName"
                                        id="companyName"
                                        className={`block w-full pl-10 pr-3 py-2 sm:text-sm border rounded-lg focus:ring-secondary focus:border-secondary ${errors.companyName ? 'border-red-500' : 'border-gray-400'}`}
                                        placeholder="Your company name"
                                        value={formData.companyName}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.companyName &&
                                    <p className="mt-2 text-sm text-red-600">{errors.companyName}</p>}
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            <div>
                                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-1">
                                    Phone Number (Optional)
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                    <div
                                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <Phone className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    </div>
                                    <input
                                        type="tel"
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        className={`block w-full pl-10 pr-3 py-2 sm:text-sm border rounded-lg focus:ring-secondary focus:border-secondary ${errors.phoneNumber ? 'border-red-500' : 'border-gray-400'}`}
                                        placeholder="Your phone number"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.phoneNumber &&
                                    <p className="mt-2 text-sm text-red-600">{errors.phoneNumber}</p>}
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                                    Email
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                    <div
                                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <Mail className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    </div>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className={`block w-full pl-10 pr-3 py-2 sm:text-sm border rounded-lg focus:ring-secondary focus:border-secondary ${errors.email ? 'border-red-500' : 'border-gray-400'}`}
                                        placeholder="your-company@example.com"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.email && <p className="mt-2 text-sm text-red-600">{errors.email}</p>}
                            </div>
                        </div>
                        <div>
                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 mb-1">
                                About Your Company
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <textarea
                                    id="about"
                                    name="about"
                                    rows={4}
                                    className="block w-full sm:text-sm border rounded-lg focus:ring-secondary focus:border-secondary border-gray-400 p-3"
                                    placeholder="Tell us about your company and how we can help you leverage AI to boost your business"
                                    value={formData.about}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-secondary hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary transition duration-300"
                            >
                                Send Message
                                <Send className="ml-2 h-5 w-5" aria-hidden="true"/>
                            </button>
                        </div>
                    </form>
                </motion.div>
                <motion.div
                    className="mt-12 bg-white p-8 rounded-lg shadow-lg"
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{delay: 0.4, duration: 0.5}}
                >
                    <h3 className="text-2xl font-semibold mb-6 text-primary">Contact Us Directly</h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <div>
                            <h4 className="text-lg font-medium mb-3 text-gray-700">Daniel Schreiber</h4>
                            <p className="flex items-center text-gray-600">
                                <Mail className="mr-2 h-5 w-5 text-secondary"/>
                                <a href="mailto:daniel@getwithai.com"
                                   className="hover:text-secondary transition duration-300">daniel@getwithai.com</a>
                            </p>
                        </div>
                        <div>
                            <h4 className="text-lg font-medium mb-3 text-gray-700">Joel Schreiber</h4>
                            <p className="flex items-center text-gray-600">
                                <Mail className="mr-2 h-5 w-5 text-secondary"/>
                                <a href="mailto:joel@getwithai.com"
                                   className="hover:text-secondary transition duration-300">joel@getwithai.com</a>
                            </p>
                        </div>
                    </div>
                </motion.div>
            </div>
            <AnimatePresence>
                {showSuccessPopup && <SuccessPopup/>}
            </AnimatePresence>
        </section>
    );
};