import React from 'react';
import {motion} from 'framer-motion';

export const Mission = () => {
    return (
        <section id="mission" className="relative py-20">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.h2
                    className="text-4xl sm:text-5xl font-bold mb-8 text-center text-primary"
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                >
                    Our Mission
                </motion.h2>
                <motion.div
                    className="space-y-8"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{delay: 0.2, duration: 0.5}}
                >
                    <p className="text-xl sm:text-2xl text-center font-light text-text">
                        Guiding you through the AI revolution
                    </p>
                    <div className="grid md:grid-cols-2 gap-8">
                        <div className="bg-background p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-4 text-primary">Adapt to Thrive</h3>
                            <p className="text-text">AI is reshaping industries at lightning speed. We ensure you're not
                                just keeping up, but leading the charge.</p>
                        </div>
                        <div className="bg-background p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-4 text-primary">Efficiency Unleashed</h3>
                            <p className="text-text">Harness AI to streamline your processes, boost productivity, and
                                unlock new revenue streams.</p>
                        </div>
                    </div>
                    <motion.div
                        className="text-center"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{delay: 0.4, duration: 0.5}}
                    >
                        <a href="#contact"
                           className="inline-block px-8 py-3 bg-secondary text-white font-semibold rounded-full hover:bg-opacity-90 transition duration-300">
                            Start Your AI Journey
                        </a>
                    </motion.div>
                </motion.div>
            </div>
        </section>
    );
};