import React from 'react';
import { motion } from 'framer-motion';

export const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-primary text-white py-6">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    className="flex flex-col items-center"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <h3 className="text-2xl font-bold mb-2">GetWithAI</h3>
                    <p className="text-sm">
                        &copy; {currentYear} GetWithAI. All rights reserved.
                    </p>
                </motion.div>
            </div>
        </footer>
    );
};