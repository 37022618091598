import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Mail, FileText, TrendingUp, ArrowRight } from 'lucide-react';

const comparisonData = [
    {
        task: 'Email Drafting',
        icon: Mail,
        aiTime: 0.17,
        humanTime: 5,
        aiCost: 0.01,
        humanCost: 4.17,
        aiMistakes: 0.1,
        humanMistakes: 0.5,
        description: 'Drafting a professional email response'
    },
    {
        task: 'Contract Review',
        icon: FileText,
        aiTime: 2,
        humanTime: 120,
        aiCost: 1,
        humanCost: 100,
        aiMistakes: 0.5,
        humanMistakes: 2,
        description: 'Reviewing a 50-page legal contract'
    },
    {
        task: 'Market Analysis',
        icon: TrendingUp,
        aiTime: 10,
        humanTime: 480,
        aiCost: 5,
        humanCost: 400,
        aiMistakes: 1,
        humanMistakes: 5,
        description: 'Analyzing market trends for a consulting project'
    }
];

const Counter = ({ end, duration }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => setCount(end), 100);
        return () => clearTimeout(timer);
    }, [end]);

    return <span className="font-bold text-lg">{count.toFixed(2)}</span>;
};

const ComparisonCard = ({ task, icon: Icon, aiTime, humanTime, aiCost, humanCost, aiMistakes, humanMistakes, description, inView }) => {
    const aiControls = useAnimation();
    const humanControls = useAnimation();

    useEffect(() => {
        if (inView) {
            aiControls.start({ width: '100%', transition: { duration: Math.max(aiTime / 10, 2), ease: [0.87, 0, 0.13, 1] } });
            humanControls.start({ width: '100%', transition: { duration: Math.min(humanTime / 2, 30), ease: "linear" } });
        } else {
            aiControls.start({ width: 0 });
            humanControls.start({ width: 0 });
        }
    }, [inView, aiControls, humanControls, aiTime, humanTime]);

    return (
        <motion.div
            className="bg-white bg-opacity-90 rounded-lg shadow-md p-4 sm:p-6 mb-6 w-full max-w-2xl"
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
        >
            <div className="flex items-center mb-4">
                <Icon className="text-secondary mr-2" size={24} />
                <h3 className="text-xl font-semibold text-primary">{task}</h3>
            </div>
            <p className="text-gray-600 mb-4">{description}</p>
            <div className="space-y-4">
                <div>
                    <p className="font-semibold text-primary">AI</p>
                    <div className="relative pt-1">
                        <motion.div
                            className="overflow-hidden h-4 text-xs flex rounded bg-secondary bg-opacity-20"
                            initial={{ width: 0 }}
                            animate={aiControls}
                        >
                            <motion.span className="w-full h-full bg-secondary"></motion.span>
                        </motion.div>
                    </div>
                    <div className="flex justify-between text-sm mt-1">
                        <span>Time: <Counter end={aiTime} /> min</span>
                        <span>Cost: $<Counter end={aiCost} /></span>
                        <span>Mistakes: <Counter end={aiMistakes} /></span>
                    </div>
                </div>
                <div>
                    <p className="font-semibold text-primary">Human</p>
                    <div className="relative pt-1">
                        <motion.div
                            className="overflow-hidden h-4 text-xs flex rounded bg-gray-300"
                            initial={{ width: 0 }}
                            animate={humanControls}
                        >
                            <motion.span className="w-full h-full bg-gray-500"></motion.span>
                        </motion.div>
                    </div>
                    <div className="flex justify-between text-sm mt-1">
                        <span>Time: <Counter end={humanTime} /> min</span>
                        <span>Cost: $<Counter end={humanCost} /></span>
                        <span>Mistakes: <Counter end={humanMistakes} /></span>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

const AIvsHumanComparison = () => {
    const [inView, setInView] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setInView(entry.isIntersecting);
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div ref={ref} className="relative py-12 sm:py-20 bg-gray-100">
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-bold mb-4 text-center text-primary">AI vs Human: Efficiency Comparison</h2>
                <p className="text-lg text-center mb-8 text-gray-600">See how AI can dramatically improve your business operations</p>
                <div className="flex flex-col items-center space-y-6 sm:space-y-8">
                    {comparisonData.map((comparison, index) => (
                        <ComparisonCard key={index} {...comparison} inView={inView} />
                    ))}
                </div>
                <div className="mt-10 text-center">
                    <a href="#contact" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondary hover:bg-opacity-90 transition duration-150 ease-in-out">
                        Start Your AI Journey <ArrowRight className="ml-2 h-5 w-5" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default AIvsHumanComparison;