import React from 'react';

export const Background = () => {
    return (
        <div className="fixed inset-0 z-0 overflow-hidden">
            {/* Large circle in the top left */}
            <div className="absolute -top-20 -left-20 w-96 h-96 bg-primary opacity-5 rounded-full"></div>

            {/* Medium circle in the bottom right */}
            <div className="absolute -bottom-32 -right-32 w-128 h-128 bg-secondary opacity-10 rounded-full"></div>

            {/* Small circle in the middle left */}
            <div className="absolute top-1/3 -left-10 w-40 h-40 bg-primary opacity-5 rounded-full"></div>

            {/* Extra small circle in the top right */}
            <div className="absolute top-1/4 right-1/4 w-24 h-24 bg-secondary opacity-10 rounded-full"></div>

            {/* Square in the bottom left */}
            <div className="absolute bottom-1/4 left-1/6 w-32 h-32 bg-primary opacity-5 transform rotate-45"></div>

            {/* Triangle in the top middle */}
            <div className="absolute top-1/6 left-1/2 w-0 h-0 border-l-[50px] border-l-transparent border-b-[86px] border-b-secondary border-r-[50px] border-r-transparent opacity-10"></div>
        </div>
    );
};