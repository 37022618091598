import React from 'react';
import { motion } from 'framer-motion';
import { Code, BookOpen, Briefcase, Lightbulb } from 'lucide-react';

const FounderCard = ({ name, role, background, education, Icon }) => (
    <motion.div
        className="bg-white p-6 rounded-lg shadow-md"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
    >
        <div className="text-3xl text-secondary mb-4">
            <Icon />
        </div>
        <h3 className="text-xl font-semibold mb-2 text-primary">{name}</h3>
        <p className="text-gray-600 mb-2">{role}</p>
        <p className="text-text mb-2">{background}</p>
        <p className="text-sm text-gray-500">{education}</p>
    </motion.div>
);

export const AboutUs = () => {
    const founders = [
        {
            name: "Daniel Schreiber",
            role: "Partner",
            background: "Diverse experience in software engineering, business development, and entrepreneurship. Founded Neutro, an environmental payment gateway, and held key roles in AI-driven startups.",
            education: "Israeli Arts and Science Academy - Biology and Computer Science",
            Icon: Briefcase
        },
        {
            name: "Joel Schreiber",
            role: "Partner",
            background: "AI enthusiast and content creator. Expertise in mathematics and computer science. Currently pursuing a Master's in Artificial Intelligence.",
            education: "BSc in Mathematics and Computer Science, The Hebrew University of Jerusalem",
            Icon: BookOpen
        }
    ];

    return (
        <section id="about" className="relative py-20">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.h2
                    className="text-4xl sm:text-5xl font-bold mb-8 text-center text-primary"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    About Us
                </motion.h2>
                <motion.div
                    className="text-xl text-center mb-12 text-text"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                >
                    <p className="mb-4">
                        At GetWithAI, we're not just consultants – we're innovators and problem-solvers passionate about harnessing the power of AI.
                    </p>
                    <p>
                        Founded by two brothers with a unique blend of startup experience, software engineering expertise, and cutting-edge AI research,
                        we're here to guide you through the AI revolution and transform your business.
                    </p>
                </motion.div>
                <div className="grid md:grid-cols-2 gap-8 mb-12">
                    {founders.map((founder, index) => (
                        <FounderCard key={index} {...founder} />
                    ))}
                </div>
                <motion.div
                    className="text-center"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                >
                    <h3 className="text-2xl font-semibold mb-4 text-primary">Our Commitment</h3>
                    <p className="text-text max-w-3xl mx-auto">
                        We're committed to demystifying AI and making it accessible to businesses of all sizes.
                        Our approach combines technical expertise with real-world business acumen,
                        ensuring that every AI solution we deliver drives tangible value for our clients.
                        From environmental initiatives to cutting-edge startups, we bring a wealth of experience
                        to help you navigate the rapidly evolving world of AI.
                    </p>
                </motion.div>
            </div>
        </section>
    );
};