import React from 'react';
import {motion} from 'framer-motion';
import {Cog, Lightbulb, RefreshCw} from 'lucide-react';


export const ServiceCard = ({title, subtitle, paragraph, Icon}) => (
    <motion.div
        className="bg-white p-8 rounded-lg shadow-md"
        initial={{opacity: 0, y: 20}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.5}}
        style={{minHeight: '300px', maxWidth: '320px'}}  // Adjust card size
    >
        <div className="text-4xl text-secondary mb-6">
            <Icon size={48}/>
        </div>
        <h3 className="text-2xl font-semibold mb-2 text-primary">{title}</h3>
        <h4 className="text-lg font-bold mb-4 text-secondary">{subtitle}</h4>
        <p className="text-text text-base leading-relaxed">{paragraph}</p>
    </motion.div>
);

export const Solution = () => {
    return (
        <section id="solution" className="relative py-24">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.h2
                    className="text-5xl sm:text-6xl font-bold mb-12 text-center text-primary"
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                >
                    Our AI Solutions
                </motion.h2>
                <motion.p
                    className="text-2xl text-center mb-16 text-text"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{delay: 0.2, duration: 0.5}}
                >
                    Navigate the evolving AI landscape with our tailored services and ongoing support.
                </motion.p>
                <div className="grid md:grid-cols-3 gap-12">
                    <ServiceCard
                        title="AI Launchpad"
                        subtitle="Ignite Your AI Journey"
                        paragraph="Unlock your business potential with our expert guidance. We'll map out automation opportunities, craft bespoke AI strategies, and equip your team with the skills to thrive in an AI-driven landscape."
                        Icon={Lightbulb}
                    />
                    <ServiceCard
                        title="Tailored Integrations"
                        subtitle="Personalized AI Solutions to your current workflow"
                        paragraph="Custom AI integrations into your workflows, designed to streamline processes from automated drafting to data analysis. We make AI adapt to your needs."
                        Icon={Cog}
                    />
                    <ServiceCard
                        title="AI Evolution Partner"
                        subtitle="Future-Proof Your Business"
                        paragraph="Navigate the ever-changing AI landscape with confidence. Our ongoing support ensures you're always leveraging cutting-edge AI advancements, keeping you steps ahead of the competition."
                        Icon={RefreshCw}
                    />
                </div>
            </div>
        </section>
    );
};