import React from 'react';
import {motion} from 'framer-motion';
import {TypewriterText} from './TypewriterText';
import {ChevronDown} from 'lucide-react';

export const Hero = () => {
    return (
        <section id="hero" className="relative min-h-screen flex flex-col justify-center items-center">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    className="space-y-8"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: 0.5}}
                >
                    <motion.h1
                        className="text-5xl sm:text-6xl font-bold mb-4 text-center text-primary"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5}}
                    >
                        <TypewriterText text="Empower Your Business with AI" speed={30}/>
                    </motion.h1>
                    <motion.h2
                        className="text-3xl sm:text-4xl font-semibold mb-6 text-center text-secondary"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{delay: 0.2, duration: 0.5}}
                    >
                        <TypewriterText text="AI Integration: The Key to Staying Competitive in Today's Market"
                                        speed={40}/>
                    </motion.h2>
                    <motion.p
                        className="text-xl sm:text-2xl text-center font-light text-text"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{delay: 0.4, duration: 0.5}}
                    >
                        <TypewriterText
                            text="AI is changing business faster than the internet revolution did. If you don't adapt now, you won't catch up later. GetWithAI will guide you through this transition, ensuring you benefit from the change instead of being left behind. Our tailored solutions make AI integration simple and effective, turning this challenge into your competitive advantage."
                            speed={50}
                        />
                    </motion.p>
                    <motion.div
                        className="flex flex-col justify-center items-center space-y-4"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{delay: 0.6, duration: 0.5}}
                    >
                        <a
                            href="#contact"
                            className="w-full sm:w-auto inline-block px-8 py-3 bg-secondary text-white font-semibold rounded-full hover:bg-opacity-90 transition duration-300 text-center"
                        >
                            Get Started
                        </a>
                        <a
                            href="https://www.valuer.ai/blog/50-examples-of-corporations-that-failed-to-innovate-and-missed-their-chance"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="w-full sm:w-auto inline-block px-8 py-3 border border-secondary text-secondary font-semibold rounded-full hover:bg-secondary hover:text-white transition duration-300 text-center"
                        >
                            Still Not Sure
                        </a>
                    </motion.div>
                </motion.div>
            </div>
            <motion.div
                className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
                initial={{opacity: 0, y: -20}}
                animate={{opacity: 1, y: 0}}
                transition={{delay: 0.8, duration: 0.5, repeat: Infinity, repeatType: 'reverse'}}
            >
                <ChevronDown className="w-8 h-8 text-secondary"/>
            </motion.div>
        </section>
    );
};