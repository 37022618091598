import React, {useEffect} from 'react';
import {Header} from './components/Header';
import {Hero} from './components/Hero';
import {Mission} from './components/Mission';
import {Solution} from './components/Solution';
import AIvsHumanComparison from './components/AIvsHumanComparison';
import {AboutUs} from './components/AboutUs';
import {ContactUs} from './components/ContactUs';
import {Footer} from './components/Footer';
import {Background} from './components/Background';
import {AlternateBackground} from './components/AlternateBackground';
import {applyTheme} from './theme';

function App() {
    useEffect(() => {
        applyTheme();
    }, []);

    return (
        <div className="App relative">
            <Background/>
            <div className="relative z-10">
                <Header/>
                <Hero/>
                <AlternateBackground>
                    <Solution/>
                </AlternateBackground>
                <Mission/>
                <AlternateBackground>
                    <AIvsHumanComparison/>
                </AlternateBackground>
                <AboutUs/>
                <AlternateBackground>
                    <ContactUs/>
                </AlternateBackground>
                <Footer/>
            </div>
        </div>
    );
}

export default App;
